<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                “乘长会客厅”深圳站，德邦证券携手非凸科技共探行业机遇与生态合作
              </p>
              <div class="fst-italic mb-2">2023年12月18日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >德邦证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/66/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，德邦证券携手非凸科技举办了“乘长会客厅”深圳站活动，就数智投资的发展机遇及生态合作展开了深度探讨。巨量均衡、澎湃基金、海浦投资、光大期货、拓璞投资、深圳本地资本等管理人、资方应邀参加。
                  </p>
                  <p>
                    近年来，德邦证券秉乘差异化发展战略，积极借助科技力量，持续推动资产管理、精品投行、投资业务、财富管理、卖方研究等业务创新，在资产证券化等业务领域跻身行业第一梯队。此次活动的开展，旨在构建开放式合作共赢生态，通过充分整合资方资源、优化管理人服务等方面，共同开创金融科技领域新未来。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/66/02-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    活动上，非凸科技深度分享了智能交易的解决方案以及实践经验。随着市场专业化程度和复杂度的提升，交易环节的每一步都对应着巨大的增量空间，管理人除了精进数据、算法、算力等核心驱动外，选择领先的智能交易服务商也是取得长期收益的重要实践。非凸科技希望通过科技的应用，更有效地连接市场参与者，促进信息共享和生态互联，通过技术和合作来应对行业面临的挑战，推动数智交易行业可持续发展，这也是开展一系列行业交流活动的初心。
                  </p>
                  <p>
                    交流环节，巨量均衡、澎湃基金、海浦投资分别展示了他们强大的投研团队和丰富的产品线，光大期货分享了对资金要求和合作方式的独特见解，而深圳本地资本则深入介绍了其独特的投资策略，引起了在场嘉宾的浓厚兴趣。此外，有关策略优势及合作收益等方面，也成为现场热议的话题。
                  </p>
                  <p>
                    未来是一个生态合作和融合创新的时代，在数智化背景下，非凸科技将继续深入理解客户需求，为客户提供高品质的产品、解决方案和服务，始终聚焦技术，做好支撑，助力行业伙伴在新时代下的持续发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News66",
};
</script>

<style></style>
